<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :close-on-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" text v-bind="attrs" v-on="on">
          Apply in batch
        </v-btn>
      </template>

      <v-card style="max-width: 500px">
        <v-toolbar color="primary" dark flat>
          <span class="text-caption text-center py-2 px-2">
            Use this feature to select which params will be used from this
            record to be populated throughout all records. After record
            validation all selected params will be applied to all records.
          </span>
        </v-toolbar>
        <v-card-text style="max-height: 350px; overflow-y: scroll">
          <v-list-item
            v-for="({ name, label, description }, index) of params"
            :key="name"
          >
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox
                  v-model="params[index].value"
                  :input-value="active"
                ></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title> {{ label }} </v-list-item-title>
                <v-list-item-subtitle>
                  {{ description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" outlined @click="save" block> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'apply-all',
  props: {
    value: {
      type: Array,
      default: () => null
    },
    isRelease: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    params: {
      get() {
        if (!this.value) {
          return this.defaultParams;
        }

        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    defaultParams() {
      const onlyTagsParams = ['tournament', 'gear'];

      if (this.isRelease) {
        return Params.filter((param) => !onlyTagsParams.includes(param.name));
      }

      return window.structuredClone(Params);
    }
  },
  methods: {
    save() {
      this.$emit('input', this.params);
      this.menu = false;
    }
  }
};

const Params = [
  {
    name: 'location',
    label: 'Location',
    value: false,
    description: 'Populate coordinates, location description and ocean'
  },
  {
    name: 'boat',
    label: 'Boat',
    value: false,
    description: 'Populate boat name'
  },
  {
    name: 'angler',
    label: 'Angler',
    value: false,
    description: 'Populate angler user'
  },
  {
    name: 'captain',
    label: 'Captain',
    value: false,
    description: 'Populate captain user'
  },
  {
    name: 'firstMate',
    label: 'First Mate',
    value: false,
    description: 'Populate first mate user'
  },
  {
    name: 'secondMate',
    label: 'Second Mate',
    value: false,
    description: 'Populate second mate user'
  },
  {
    name: 'tournament',
    label: 'Tournament',
    value: false,
    description: 'Populate tournament event'
  },
  {
    name: 'hook',
    label: 'Hook',
    value: false,
    description: 'Populate hook type'
  },
  {
    name: 'bait',
    label: 'Bait',
    value: false,
    description: 'Populate bait type'
  },
  {
    name: 'gear',
    label: 'Gear',
    value: false,
    description: 'Populate gear type'
  },
  {
    name: 'species',
    label: 'Species',
    value: false,
    description: 'Populate species'
  }
];
</script>

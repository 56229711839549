<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn block :x-large="full" color="primary" @click="askMultipleTags">
          <v-icon left> mdi-tag-text </v-icon>
          Submit a tag
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          block
          :x-large="full"
          dark
          color="green"
          @click="askMultipleReleases"
        >
          <v-icon left> mdi-fish </v-icon>
          Submit a release
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          block
          :x-large="full"
          dark
          color="orange"
          @click="openDialog('Recaptures')"
        >
          <v-icon left> mdi-fishbowl-outline </v-icon>
          Submit a recapture
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          block
          :x-large="full"
          dark
          color="indigo"
          @click="openDialog('Juvenile')"
        >
          <v-icon left> mdi-fishbowl </v-icon>
          Submit a juvenile
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { notifyConfirmation } from '../../helpers/notifications';

export default {
  name: 'record-entry',
  data: () => ({}),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    askMultipleTags() {
      notifyConfirmation(
        'Do you want to submit a tag or do you want to use an excel file to import a batch?',
        'Submit tags',
        () => this.openDialog('Tags'),
        () => this.$router.push({ path: '/batch-import?recordType=tags' }),
        'Submit a tag',
        'Upload multiple at once',
        false
      );
    },

    askMultipleReleases() {
      notifyConfirmation(
        'Do you want to submit a release or do you want to use an excel file to import a batch?',
        'Submit releases',
        () => this.openDialog('Releases'),
        () => this.$router.push({ path: '/batch-import?recordType=releases' }),
        'Submit a release',
        'Upload multiple at once',
        false
      );
    },

    openDialog(type) {
      this.$emit('open', type);
    },

    async mailRecapture() {
      const subject = 'New recapture';
      const body = `
      Tag number: \n
      Retag fish: \n
      Boat: \n
      Submission date: \n
      \n
      Angler's name: \n
      Captain's name: \n
      First Mate's name: \n
      \n
      Ocean: \n
      Species: \n
      Fish's length: \n
      Length determination: \n
      Fish's weight: \n
      Weight determination: \n
      Fish's condition: \n
      Bait's type: \n
      Gear's type: \n
      Event: \n
      \n      
      Location description: \n
      `;

      const hrefURL = `
      mailto:adrienne_katz@billfish.org?subject=${subject}
      &body=${encodeURIComponent(body)}
      `;

      const a = document.createElement('a');
      a.href = hrefURL;
      document.body.appendChild(a);
      a.click();
    }
  }
};
</script>

<style scoped>
.col {
  padding: 0;
}
.v-btn {
  border-radius: 0;
}
</style>

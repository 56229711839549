<template>
  <v-dialog
    v-model="onRecord"
    max-width="1080px"
    scrollable
    :fullscreen="full"
    persistent
  >
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card title>
      <v-toolbar flat dark color="primary" max-height="100px">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> {{ title }} </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text ref="contentCard">
        <release-form ref="releasesForm" v-if="isRelease" />
        <tag-form ref="tagsForm" :isRecapture="isRecapture" v-else />
      </v-card-text>
      <v-toolbar flat dark color="primary" dense>
        <v-spacer />
        <confirmation
          ref="confirmation"
          :data="data"
          :event="type"
          :isNew="!eventType"
          @check="checkForm"
          @save="saveRecord"
        />
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import {
  CoordinatesViewModel,
  CaptureViewModel,
  CrewViewModel
} from '@/views/users/viewmodels';
import Confirmation from './sections/Confirmation.vue';
import {
  getUserInfo,
  notifyConfirmation,
  notifyMessage
} from '../../../helpers/handler';
import TagForm from './sections/TagForm.vue';
import ReleaseForm from './sections/ReleaseForm.vue';
export default {
  name: 'record-fish',
  components: { Confirmation, TagForm, ReleaseForm },
  data: () => ({
    type: 'tags',
    newRecord: false,
    docs: null,
    user: null,
    data: window.structuredClone(initInput),
    open: false,
    onRecord: false,
    overlay: false,
    eventType: null,
    havePriorTag: false
  }),
  computed: {
    title() {
      if (this.eventType) {
        switch (this.eventType) {
          case 'R':
            return 'RELEASE';

          case 'C':
            return 'RECAPTURE';

          case 'T':
          default:
            return 'TAG';
        }
      }

      return String(this.type || '')
        ?.substr(0, this.type.length - 1)
        ?.toUpperCase();
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isRelease() {
      return this.title === 'RELEASE';
    },
    isRecapture() {
      return this.title === 'RECAPTURE';
    }
  },
  methods: {
    async init(data = {}, type) {
      type && (this.type = String(type).toLowerCase());
      this.onRecord = true;
      this.newRecord = !data?.record;
      if (!this.newRecord) {
        this.eventType = data.record.isTagOrRelease;
        this.havePriorTag = data.record?.priorTag?.priorTagNumber;
      }

      setTimeout(async () => {
        this.$refs.contentCard && (this.$refs.contentCard.scrollTop = 0);
        if (this.isRelease) {
          this.$refs.releasesForm &&
            (await this.$refs.releasesForm.initRecord(data?.record));
        } else {
          this.$refs.tagsForm &&
            (await this.$refs.tagsForm.initRecord(data?.record));
        }
      }, 50);
    },
    close() {
      this.$refs.fishingLocations && this.$refs.fishingLocations.close();
      this.onRecord = false;
    },
    async checkForm() {
      let input = {};
      let emptyNote = false;
      if (this.isRelease) {
        input = await this.$refs.releasesForm.submit();
        emptyNote = !input?.data?.capture?.notes;
      } else {
        input = await this.$refs.tagsForm.submit();
      }

      const user = getUserInfo();

      let payload = {
        ...window.structuredClone(initInput),
        ...window.structuredClone(input?.data),
        updatedBy: user?.email
      };

      if (this.newRecord) {
        payload = { ...payload, enteredBy: user?.email };
      } else if (Object.keys(payload).some((key) => key === 'enteredBy')) {
        delete payload.enteredBy;
      }

      if (input?.valid) {
        if (emptyNote) {
          notifyConfirmation(
            'Did you enter the red six digit number of the release card in the notes section?',
            'Warning',
            () =>
              this.$refs.releasesForm && this.$refs.releasesForm.focusNotes(),
            async () => this.data && (await this.check(this.data)),
            'No',
            'Dismiss'
          );
        }
        this.data = { ...payload };
        !emptyNote && this.data && (await this.check(this.data));
      } else {
        if (input.valid === null) {
          return;
        }
        this.$refs.confirmation && (this.$refs.confirmation.open = false);
        notifyMessage('Some fields in the form need to be fulfilled');
      }
    },
    async saveRecord(type) {
      let open = false;
      let response = null;
      this.data.draft = false;

      switch (this.title) {
        case 'TAG':
          response = await (this.newRecord
            ? this.addTag(this.data)
            : this.editTag(this.data));
          break;

        case 'RELEASE':
          response = await (this.newRecord
            ? this.addRelease(this.data)
            : this.editRelease(this.data));
          break;

        case 'RECAPTURE':
          response = await (this.newRecord
            ? this.addRecapture(this.data)
            : this.editRecapture(this.data));
          break;

        default:
          break;
      }

      const {
        success,
        message,
        error,
        response: record
      } = response?.data || {};
      if (success) {
        const { angler, captain, firstMate, secondMate, id, _id } =
          record || {};
        const crew = [angler, captain, firstMate, secondMate]
          .map((user) => user?.id)
          .filter(Boolean);
        this.$emit('done', {
          recordType: this.title,
          recordId: id || _id,
          crew
        });
        notifyMessage('The record has been registered');
        if (type) {
          open = true;
          if (this.isRelease) {
            type === 'new'
              ? this.$refs.releasesForm.initRecord()
              : this.$refs.releasesForm.resetInfo();
          } else {
            type === 'new'
              ? this.$refs.tagsForm.initRecord()
              : this.$refs.tagsForm.resetInfo();
          }
        }
        open &&
          this.$refs.contentCard &&
          (this.$refs.contentCard.scrollTop = 0);
        this.onRecord = open;
      } else notifyMessage(message || error?.message || 'Unable to save data');

      if (this.$refs.confirmation) {
        this.$refs.confirmation.open = false;
      }
    },
    async check(data) {
      let check = { event: this.type, payload: data };

      if (this.newRecord) {
        this.overlay = true;
        const { data } = (await this.checkEvent(check)) || {};
        this.overlay = false;
        if (data?.docs) {
          if (this.isRelease) {
            notifyMessage(
              "The release record you're trying to enter already exists"
            );
            return;
          }
          notifyMessage(
            'The tag number you´re trying to enter already exists. Please, change it'
          );
          return;
        }
      }

      if (this.$refs.confirmation) {
        this.$refs.confirmation.open = true;
      }
    },
    ...mapActions('entry', [
      'addTag',
      'editTag',
      'addRelease',
      'editRelease',
      'addRecapture',
      'editRecapture',
      'checkEvent'
    ])
  }
};

const initInput = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel()
};
</script>

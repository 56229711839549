<template>
  <div>
    <v-dialog v-model="onCreateLocation" max-width="800px" :fullscreen="!full">
      <new-location ref="newLocation" @close="onCreateLocation = false" />
      <!-- @location="(value) => (item = value)" -->
    </v-dialog>
    <v-autocomplete
      id="autocomplete-location"
      :items="docs"
      :label="label"
      :loading="loading"
      v-model="item"
      item-text="name"
      item-value="name"
      hide-details
      :search-input.sync="searchInput"
      :rules="rules"
      return-object
      no-filter
      :prepend-icon="
        edit ? 'mdi-circle-edit-outline' : add ? 'mdi-map-marker-plus' : null
      "
      @click:prepend="edit ? $emit('edit') : (onCreateLocation = true)"
      @click:clear="() => getLocationByName()"
      outlined
      dense
      clearable
      attach
    >
      <template v-slot:no-data>
        <div
          class="text-caption mx-5 font-weight-medium"
          v-text="'Type to search'"
        />
      </template>
      <!-- <template v-slot:item="{ item }">
        <span v-if="!item['distance']">{{ item.name }}</span>
        <v-list-item v-else class="pl-0">
          <v-list-item-content>
            <span class="text-subtitle-2">{{ item.name }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-action>
          <span class="font-weight-thin text-caption">{{
            item.distance + 'mi'
          }}</span>
        </v-list-item-action>
      </template> -->
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import NewLocation from '../../views/users/forms/sections/NewLocation.vue';
// import { CoordinatesViewModel } from '../../views/users/viewmodels';
import { validCoordinates } from '../../helpers/map';
export default {
  components: { NewLocation },
  name: 'autocomplete-location',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object || String,
      default: () => null
    },
    editable: {
      type: Boolean,
      default: () => false
    },
    addNew: {
      type: Boolean,
      default: false
    }
    // coordinates: {
    //   type: Object,
    //   default: () => new CoordinatesViewModel()
    // }
  },
  data: () => ({
    docs: [],
    search: null,
    loading: false,
    onCreateLocation: false,
    tempName: null
  }),
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    newLocationValue() {
      if (typeof this.value === 'string') {
        return {
          name: this.value,
          ocean: null,
          coordinates: null
        };
      }

      const { description } = this.value || {};
      return {
        name: description,
        ocean: null,
        coordinates: this.value
      };
    },
    locationName() {
      if (typeof this.value === 'string') {
        return this.value;
      }

      const { description } = this.value || {};
      return description;
    },
    edit() {
      return this.editable && Boolean(this.locationName);
    },
    add() {
      return this.addNew && !this.locationName;
    },
    searchInput: {
      get: function () {
        return this.search;
      },
      set: function (search) {
        if (search !== this.search) {
          this.search = search;
          search ? this.getLocationByName(search) : (this.docs = []);
        }
      }
    },
    item: {
      get: function () {
        if (!this.locationName) {
          return null;
        }

        return String(this.locationName).toUpperCase();
      },
      set: function (item) {
        const { name, coordinates, ocean } = item || {};

        if (coordinates) {
          coordinates.description = name;
        }

        this.$emit('input', coordinates);
        // this.$emit('locate', coordinates);
        this.$emit('ocean', ocean?.name || ocean);
      }
    },
    coordinates() {
      return this.value;
    }
  },
  methods: {
    ...mapActions('query', ['getLocation']),
    async searchValue(value) {
      if (!value) {
        await this.getLocationByName();
        return;
      }

      const locationName = String(value).toUpperCase();
      const isExist = this.docs.some(({ name }) => name === locationName);

      if (isExist) {
        return;
      }

      if (this.tempName === locationName) {
        return;
      }

      this.tempName = locationName;

      await this.getLocationByName(value);
    },
    async getLocationByName(name = null) {
      this.loading = true;

      const payload = { name, page: 1, limit: 10, type: 'approved' };
      const validCoords = validCoordinates(this.coordinates);
      if (validCoords) {
        payload['coordinates'] = this.coordinates;
      }

      const response = await this.getLocation(payload);
      const { docs } = response?.data || {};

      if (!docs) {
        this.$emit('input', { ...this.value, description: null });
        this.tempName = null;
        this.loading = false;
        return;
      }

      this.docs = docs.map((item) => ({
        ...item,
        name: String(item.name).toUpperCase()
      }));

      this.loading = false;
    }
    // async getLocations(coordinates) {
    //   const validCoords = validCoordinates(coordinates);
    //   if (validCoords) {
    //     await this.getLocationByName();
    //   }
    // }
  },
  watch: {
    // coordinates: {
    //   handler: 'getLocations'
    // },
    onCreateLocation: {
      handler: function (value) {
        if (value) {
          setTimeout(() => {
            this.$refs.newLocation &&
              this.$refs.newLocation.initForm(this.newLocationValue);
          }, 50);
        }
      }
    },
    locationName: {
      handler: 'searchValue',
      immediate: true
    }
  }
};
</script>
